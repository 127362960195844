const MegaMenuItems = [
  {
    title: "Launch & Kickoff",
    submenu: [
      {
        link: "/on-demand/pitch-deck",
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/pitch_deck_ZwpFXPXaN.svg",

        title: "Pitch Deck",
        subtitle:
          "High-quality pitchdesks and to impress your investors & stakeholders",
      },
      {
        link: "/on-demand/logo-design",
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/logo_design_LpkmSMU_2.svg",
        title: "Logo Design",
        subtitle:
          "Get your business a brand new logo or brand identity designed. ",
      },
      {
        link: "/on-demand/stationary",
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/stationary_kit_bQXr0W0Z5M.svg",
        title: "Stationery Kit",
        subtitle:
          "Show-off your business with stunning business cards & Stationery Kits. ",
      },
      {
        link: "/on-demand/videos",
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/video_neyY0IrAOa.svg",
        title: "Promo Videos",
        subtitle:
          "Tell your business and product story with striking visuals & stunning videos!",
      },
      {
        link: "/on-demand/brand-style",
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/video_neyY0IrAOa.svg",
        title: "Brand Styleguide",
        subtitle:
          "Tell your business and product story with striking visuals & stunning videos!",
      },
    ],
  },
  // {
  //   title:'Digital Marketing & SEO',
  //   submenu:[
  //     {
  //       icon:"https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/digital_marketing_e0GAnJPD6.svg",
  //       title:"Digital Marketing",
  //       subtitle:"Drive growth, revenue & more with holistic Digital Marketing Subscriptions ",
  //       link:'/c/digital-marketing'
  //     },
  //     {
  //       icon:"https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/social-media_q_8GTr69t.svg",
  //       title:"Social Media",
  //       subtitle:"Scale your social media efforts with comprehensive Social media subscriptions",
  //       link:'/c/social-media'
  //     },
  //     {
  //       icon:"https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/seo_LOQkxtDuX.svg",
  //       title:"SEO",
  //       subtitle:"Boost your SEO & Content Marketing efforts with holistic SEO subscriptions",
  //       link:'/c/seo'
  //     },
  //     {
  //       icon:"https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/app_search_optimization_t_C8RRNKPV.svg",
  //       title:"App Search Optimization",
  //       subtitle:"Drive more users to your mobile apps with ASO Plans",
  //       link:'/c/aso'
  //     },
  //     {
  //       icon:"https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/digital_all_access_NY11cnUbh.svg",
  //       title:"Digital All Access",
  //       subtitle:"Save time, money big time with All access passes designed for your growth",
  //       link:'/c/digital-marketing'
  //     }
  //   ]
  // },
  {
    title: "Graphics & UI/UX",
    submenu: [
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/ui_ux_s0tkH_6yK1.svg",
        title: "UI-UX",
        subtitle:
          "Build Products that your users love with holistic UI/UX Subscription Plans",
        link: "/c/ui-ux",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/graphic_design_jWWefsoBW.svg",
        title: "Graphic Design",
        subtitle:
          "Connect, communicate, and engage better with flexible Graphic Design Plans",
        link: "/c/graphic-design",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/design_all_access_QafHrPel1.svg",
        title: "Design All Access",
        subtitle:
          "Boost your online presence with All access passes designed for your growth ",
        link: "/c/ui-ux",
      },
    ],
  },
  {
    title: "Content & Video",
    submenu: [
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/articles_and_blogs_Sa_xPYvw6.svg",
        title: "Articles & Blogs",
        subtitle:
          "Get SEO optimized articles and blogs with holistic articles & blog subscriptions",
        link: "/c/article-and-blogs",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/cxo_marketing_SJqvSDEuM.svg",
        title: "CXO Marketing",
        subtitle:
          "Enhance your personal & professional brand on social media with  ",
        link: "/c/cxo-marketing",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/all_in_one_content_and_copywriting_VQRD6CIN5.svg",
        title: "All-in-one Content & Copywriting",
        subtitle: "Flexible Content Plans for all your content needs ",
        link: "/c/content-all",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/video_essentials_Z0-0lFliR.svg",
        title: "Video Essentials",
        subtitle:
          "Amplify your online presence with great videos and flexible video plans ",
        link: "/c/video",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/video_all_access_f18bvj1Kx.svg",
        title: "Video All Access",
        subtitle:
          "Professionally edited videos of any duration of any type with All access passes.",
        link: "/c/video",
      },
    ],
  },
  {
    title: "Technology",
    submenu: [
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/web_development_xo2vO5a6A.svg",
        title: "Web Development",
        subtitle:
          "Build Robust Websites & Web Apps with holistic web development subscriptions",
        link: "/c/web-dev",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/mobile_app_dev_nTVx2RVQkV.svg",
        title: "Mobile App Dev",
        subtitle:
          "Build scalable android/iOS apps with comprehensive mobile app dev plans",
        link: "/c/mobile-app-dev",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/tech_all_access_PnzK4TeNx.svg",
        title: "Tech All Access",
        subtitle:
          "Build & develop future ready applications with All access passes built for your growth  ",
        link: "/",
      },
    ],
  },
  {
    title: "All Access",
    submenu: [
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/digital_all_access_NY11cnUbh.svg",
        title: "Digital All Access",
        subtitle:
          "Save time, money big time with All access passes designed for your growth",
        link: "/all-access/digital",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/design_all_access_QafHrPel1.svg",
        title: "Design All Access",
        subtitle:
          "Boost your online presence with All access passes designed for your growth ",
        link: "/all-access/design",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/tech_all_access_PnzK4TeNx.svg",
        title: "Tech All Access",
        subtitle:
          "Build & develop future ready applications with All access passes built for your growth  ",
        link: "/all-access/tech",
      },
      {
        icon: "https://ik.imagekit.io/99x/startup/icons/megamenu/dm_and_sm/video_all_access_f18bvj1Kx.svg",
        title: "Video All Access",
        subtitle:
          "Professionally edited videos of any duration of any type with All access passes.",
        link: "/all-access/studio",
      },
    ],
  },
];

export default MegaMenuItems;
