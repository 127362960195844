const MegaMenuItems = [
    {
        title:"Graphic Design",
        link:"/c/graphic-design"
    },
    {
        title:"UI/UX",
        link:"/c/ui-ux"
    },
    {
        title:"On-Demand Services",
        type: "megamenu",
        megamenu:[
            {
                title:"Graphic Design",
                icon: "https://ik.imagekit.io/99x/design/_new/Group_54_QGwznqFi1ab.png",
                submenu:[
                    {
                        link:"/on-demand/illustrations",
                        icon:"https://ik.imagekit.io/99x/design/_new/BrandStyleGuide_RbpogFKtiH0M.svg",
                        title:"Illustrations",
                        subtitle:"Stunning illustrations and graphics that draws attention"
                    },
                    {
                        link:"/on-demand/presentation-design",
                        icon:"https://ik.imagekit.io/99x/design/_new/Presentation_i9f7PA6DuA5F.svg",
                        title:"Presentations",
                        subtitle:"Presentaitions designed to rock the boardroom meetings "
                    },
                    {
                        link:"/on-demand/menu-design",
                        icon:"https://ik.imagekit.io/99x/design/_new/BrandStyleGuide_1__S5_0WTAvL80.svg",
                        title:"Menu Design",
                        subtitle:"Tasty menus to show off what you’re cooking up "
                    },
                    {
                        link:"/on-demand/brochure-design",
                        icon:"https://ik.imagekit.io/99x/design/_new/BrandStyleGuide_2__nfwPC6ZacNF2.svg",
                        title:"Brochures",
                        subtitle:`Engage and “Namaste” your clients with printable and foldable brochures.`
                    },
                    {
                        link:"/on-demand/packaging-designs",
                        icon:"https://ik.imagekit.io/99x/design/_new/Icon_jkQuOUkgMR1B.svg",
                        title:"Product Packaging",
                        subtitle:`Engage and “Namaste” your clients with printable and foldable brochures.`
                    },
                    {
                        href:"/",
                        icon:"https://ik.imagekit.io/99x/design/_new/Screenshot_2021-07-29_at_1.53.50_AM_KkoOXnw9qRH.png",
                        title:"",
                        subtitle:""
                    },
                ]
            },
            {
                title:"Web & Apps",
                icon:'https://ik.imagekit.io/99x/design/_new/Group_29_WbZIJ3UmJpL.png',
                submenu:[
                    {
                        link:"/on-demand/web-design",
                        icon:"https://ik.imagekit.io/99x/design/_new/Icon_1__X4ZdCesfR7t.png",
                        title:"Web Design",
                        subtitle:`Engaging custom web designs that connects with your visitors`
                    },
                    {
                        link:"/on-demand/mobile-app-design",
                        icon:"https://ik.imagekit.io/99x/design/_new/Icon_bTagA-PYuMiM.png",
                        title:"App Design",
                        subtitle:`App-etizing designs that charms your users, coming back for more.`
                    },
                    {
                        link:"/on-demand/brand-styleguide",
                        icon:"https://ik.imagekit.io/99x/design/_new/DS_UB_XQsqmQMn.png",
                        title:"Design System",
                        subtitle:"A developer-friendly design system for consistent and intuitive experience"
                    },
                    {
                        href:"/",
                        icon:"https://ik.imagekit.io/99x/design/_new/Screenshot_2021-07-29_at_1.53.50_AM_KkoOXnw9qRH.png",
                        title:"",
                        subtitle:""
                    },
                ]
            },
            {
                title:"Branding",
                icon:'https://ik.imagekit.io/99x/design/_new/Group_29_WbZIJ3UmJpL.png',
                submenu:[
                    {
                        link:"/on-demand/logo-design",
                        icon:'https://ik.imagekit.io/99x/design/_new/Icon_L1Yh6CgfRZY.png',
                        title:'Logo Design',
                        subtitle:'Get your business a brand new logo or brand identity designed. '
                    },
                    {
                        link:"/on-demand/brand-styleguide",
                        icon:'https://ik.imagekit.io/99x/design/_new/BrandStyleGuide_AMfbWM-3GoN.png',
                        title:'Brand Styleguide',
                        subtitle:`A comprehensive guide of your brand's fonts, colors and style`
                    },
                    {
                        link:"/on-demand/business-cards-&-stationery-kit",
                        icon:'https://ik.imagekit.io/99x/design/_new/Frame_SKDfyiTbOCg9.png',
                        title:'Stationery Kits',
                        subtitle:`Letterhead and envelopes that send your brand's message perfectly`
                    },
                    {
                        link:"/on-demand/business-cards-&-stationery-kit",
                        icon:'https://ik.imagekit.io/99x/design/_new/Icon_1__JCasKWvN6G1r.png',
                        title:'Business Cards',
                        subtitle:'Level Up your networking game with professional business card designs'
                    },
                    {
                        link:"/on-demand/animated-logo",
                        icon:'https://ik.imagekit.io/99x/design/_new/Icon_2__Exm8SefucY66.png',
                        title:'Animated Logos',
                        subtitle:'Level Up your networking game with professional business card designs'
                    },
                    {
                        link:"/on-demand/",
                        icon:'',
                        title:'',
                        subtitle:''
                    },
                ]
            },
            {
                title:"Digital & Social",
                icon:'https://ik.imagekit.io/99x/design/_new/Group_76_LN6un6SmYuJ.png',
                submenu:[]
            }
        ]
    },
    {
        title:"All Access Pass",
        link:"/all-access/design"
    },
    {
        title:"Our Work",
        link:"/our-work"
    },
]

export default MegaMenuItems

