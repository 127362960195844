export const CATEGORY_LIST = 'CATEGORY_LIST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAILURE = 'CATEGORY_LIST_FAILURE';
export const RESET_CATEGORY_LIST = 'RESET_CATEGORY_LIST';

export const CATEGORY_SERVICES_LIST = 'CATEGORY_SERVICES_LIST';
export const CATEGORY_SERVICES_LIST_SUCCESS = 'CATEGORY_SERVICES_LIST_SUCCESS';
export const CATEGORY_SERVICES_LIST_FAILURE = 'CATEGORY_SERVICES_LIST_FAILURE';
export const RESET_CATEGORY_SERVICES_LIST = 'RESET_CATEGORY_SERVICES_LIST';

export const SERVICES_DETAILS = 'SERVICES_DETAILS';
export const SERVICES_DETAILS_SUCCESS = 'SERVICES_DETAILS_SUCCESS';
export const SERVICES_DETAILS_FAILURE = 'SERVICES_DETAILS_FAILURE';
export const RESET_SERVICES_DETAILS = 'RESET_SERVICES_DETAILS';